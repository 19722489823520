$(".faq.active .respuesta").css("display", "block"); 
$(".vermieter .pregunta").on("click", function () {
  $(".vermieter .faq.active .respuesta").hide(500);
  $(".vermieter .faq").removeClass("active");
  $(this).parent().addClass("active");
  $(this).siblings().show(500);
});

$("#faq-first").on("click", function() {
	$(".second__faq").hide();
	$(".first__faq").show();
});

$("#faq-second").on("click", function() {
	$(".first__faq").hide();
	$(".second__faq").show();
});